import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Dellbrueck: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Dellbrueck,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Dellbrück"
          d="M903.9,443.4l0.5,0.2l0.2,0.3l-0.1,0.7c-2.1,1.5-2.3,3.3-2.7,5.6l-2.4,0.2c0,0.6,0.2,1.6,0.1,2.2l2.9,0.8 c0.6,0.9,1.6,3.1,1.6,4.2c2.4-0.1,5.7-0.6,7.8-1.7l1.1,2.3c-0.4,0.5-2.7,3.8-3.2,4.1c-1.9,1-4,3-5.5,4.6c-0.5,3.3-2.7,9.4-0.8,12.6 c-0.3,0.6-0.3,1.8-0.1,2.4c-0.2,0.3-0.6,0.8-0.6,1.2c0,0,0.2,0.9,0.2,0.9l0.4,0.2l1.9-1.1c1.4,3.5,4,6.6,5.8,9.9l1.4-1.2 c0.3,0.5,0.5,1.3,0.3,1.9c0.4,1.1,2,5.3,2.5,6.2c0.5,4,1,8.9,0.8,12.9c-2.7,5-6.7,12.3-9.2,17.3l-2.7-0.5c-4.8,2-7.9,7-12.1,9.6 l0.5,1.2c-0.7,0.3-1.6,1-2.1,1.6l1.1,1.8c-3.4,2.1-8,4.4-12,5.1l0.2,0.9c-0.9,0-49.9,23.9-50.7,24.2l-10-14l-21-21l22-23l-20-12 l-14-32l-8-32l47.2-35.9l-0.3,0.7c0.5,0,1.7,0,2.2,0.2c0.5,1.2,1.8,3.8,2.7,4.8c0,0,1,2.8,1,2.8l1.8,0.4l0.5,0.5 c-0.5,2.2-1.7,6-3.1,7.8l0.1,0.5c4.8,0.4,12.2,1.1,17.1,1.3c4.4,1.3,11.9,1.8,16.5,1.8c0.4,1.3,6.4,3.9,7.5,4.7c0.3,0,5.8,0,6,0.2 c0.6-0.2,3.3-0.6,4-0.7c0.1,2.5-0.2,6.6,0,9.1l2.8-2.1c4.6,1.6,10.8,3.3,15.7,3.2v1.7l3,0.1L903.9,443.4z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 825.1368 488.261)"
        >
          Dellbrück
        </text>
      </g>
    </>
  );
});
